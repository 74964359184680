<template>
  <div>
    <CCard>
      <CCardHeader>Edit Admin User</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md='12' lg='6'>
            <CForm @submit='submitForm'>
              <CInput v-model='usernameInput.val' :readonly='true'>
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput placeholder='Password' type='password' v-model='passwordInput.val' v-bind:class="{ valid: validateInput(passwordInput.val, 'password') }" :invalidFeedback="invalidFeedback('password')" :isValid="validateInput(passwordInput.val, 'password')">
                <template #prepend-content><CIcon name='cil-shield-alt'/></template>
              </CInput>
              <div class='form-group'>
                <CInputRadioGroup inline :options="[{ value: '0', label: 'Active'}, { value: '1', label: 'Inactive'}]" :checked.sync='statusInput.val'/>
              </div>
              <div class='form-group form-actions'>
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Update
                </CButton>
              </div>
              <div class='form-group form-actions'>
                <CLink @click='deleteItemModalShow()' :disabled='deleteLoading'>
                  <CIcon name='cil-trash' /> Delete this admin user <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='deleteLoading'></span>
                </CLink>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal title='Delete Admin User' color='danger' :centered='true' :show.sync='deleteModal' @update:show='deleteItemConfirm'>
      Are you sure you wish to delete this admin user '{{usernameInput.val}}'?
    </CModal>
  </div>
</template>

<script>
import apiAdmin from '@/api/admin'

export default {
  name: 'EditAdmin',
  components: {
  },
  data () {
    return {
      formLoading: false,
      deleteLoading: false,
      usernameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      passwordInput: { val: null, serverErrMsg: null , serverErrVal: null },
      statusInput: { val: null, serverErrMsg: null , serverErrVal: null },
      deleteModal: false
    }
  },
  created() {
    apiAdmin.get(this.$route.params.id).then(response => {
      this.usernameInput.val = response.data.admin.username
      this.statusInput.val = ''+response.data.admin.status
    }).catch((err) => {
      this.$toast.error('Error retrieving item.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    })
  },
  methods: {
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'password'){ // 6 to 16 characters
          return (val && ((val.length >= 6 && val.length <= 16 && val !== this.passwordInput.serverErrVal) || val == '')) ? true : false
        }
        if(field == 'status'){ // 0 or 1
          return (val && (val == '0' || val == '1') && val !== this.statusInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'password'){
        return (this.passwordInput.val !== this.passwordInput.serverErrVal) ? '6 to 16 characters' : this.passwordInput.serverErrMsg
      }
      /*if(field == 'status'){
        return (this.statusInput.val !== this.statusInput.serverErrVal) ? 'Invalid status code' : this.usernameInput.serverErrMsg
      }*/
    },
    submitForm: function (e) {
      
      e.preventDefault();

      if( ( (this.passwordInput.val == '' || this.passwordInput.val === null) || (this.validateInput(this.passwordInput.val, 'password')) ) && this.validateInput(this.statusInput.val, 'status')){
        
        this.formLoading = true

        apiAdmin.update(this.$route.params.id, this.passwordInput.val, parseInt(this.statusInput.val)).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully updated admin.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'password'){
                this.passwordInput.serverErrVal = this.passwordInput.val
                this.passwordInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to update admin.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })

      }

    },
    deleteItemModalShow() {
      this.deleteModal = true
    },
    deleteItemConfirm(show, event, accepted) {
      if(accepted){
        this.deleteLoading = true
        apiAdmin.delete(this.$route.params.id).then(response => {
          this.deleteLoading = false
          this.$toast.success('Successfully deleted admin.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.deleteLoading = false
          this.$toast.error('Failed to delete admin.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })
      }
    }
  }
}
</script>
